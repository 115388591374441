@charset "utf-8";


// Set your brand colors
$youngcircle-color: #482480;
$youngcircle-color-secondary: #11adb5;
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;

@import "../../node_modules/bulma/sass/utilities/_all";

// Update Bulma's global variables
$family-sans-serif: "GillSans", sans-serif;
$family-primary: "GillSans", sans-serif;
$family-secondary: "Crewniverse", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $youngcircle-color;
$link: $youngcircle-color-secondary;
$widescreen-enabled: false;
$fullhd-enabled: false;
$label-color: white;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$body-font-size: 3.5em;
$size-large: 5rem;
$body-color: white;
$button-color: white;
$button-border-color: none;


@import "../../node_modules/bulma/bulma.sass";

$base-font-size: 1;
$base-min-width: 200;
$youngcircle-color: #482480;
$youngcircle-color-secondary: #11adb5;

html {
  font-size: 1rem;
}

.explainer, .thankyou {
  overflow-y: scroll !important;
  height: 100%;

  p {
    margin-bottom: #{$base-font-size * 2}rem;
  }

  ol {
    list-style-position: inside;

    li {
      margin-bottom: #{$base-font-size * 2}rem;
    }
  }

  a {
    color: $youngcircle-color-secondary;
    text-decoration: underline;
  }

  a.button {
    background-color: $youngcircle-color-secondary;
    text-decoration: none;
  }
}

.button {
  margin-bottom: 5rem;
  margin-top: 5rem;
}

.button.is-large {
  font-family: $family-secondary;
  background-color: $youngcircle-color-secondary
}

.button.help {
  font-family: $family-secondary;
  background-color: $youngcircle-color-secondary;
  font-size: 3rem;
}

.help.is-danger{
  font-size: 3rem;
  color: white;
}


@media(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  html {
    font-size: 0.8rem;
  }
}

@media(-webkit-min-device-pixel-ratio: 3),
(min-resolution: 288dpi) {
  html {
    font-size: 1rem;
  }
}

@include desktop() {
  html {
    font-size: 1.3rem;
  }
}

html, body {
  height: 100%;
  margin: 0;
  background: linear-gradient(225deg, #D91056 0%, #482480 50.01%, #02ADB5 100%);
  overflow: hidden;
}

body {
  background-image: url('../../public/assets/images/shine.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

